
import { computed, defineComponent, PropType, reactive, ref, watch, watchEffect } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { useNotify } from '@/store'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'

import { isRequired } from '@/utils/validation'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useCreateTask, useGetTasks, useUpdateTask } from '@/api/task'
import { useGetUsersBasic } from '@/api/user'

import { TaskInput, TaskOutput, TaskId, TaskUpdateInput } from '@/api/types/task'
import { SupplierId } from '@/api/types/supplier'
import { ContractId } from '@/api/types/contract'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { AssignmentId } from '@/api/types/assignment'
import { PersonId } from '@/api/types/person'
import { EquipmentId } from '@/api/types/equipment'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'AddEditTaskDialog',
  components: {
    CommonAddEditDialog,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isBoarding: {
      type: Boolean,
      default: false,
    },
    entityName: {
      type: String,
      default: null,
    },
    entityId: {
      type: Number as PropType<SupplierId | ContractId | AssignmentId | PersonId | TaskId | EquipmentId>,
      default: null,
    },
    taskToEdit: {
      type: Object as PropType<TaskOutput>,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()

    if (hasSufficientRights(Rights.BASIC_READ)) {
      getUsersBasic()
    }

    const { exec: getTasks, data: tasks, isLoading: isLoadingTasks } = useGetTasks()
    getTasks()

    const DYNAMIC_FIELDS = props.isBoarding
      ? []
      : [
          {
            value: 'type',
            fieldType: FORM_FIELDS_ENUM.TEXT,
            dataTyp: DATA_TYPE.TEXT,
            isRequired: false,
            rules: [],
          },
        ]

    const FORM_FIELDS: FormField[] = [
      {
        value: 'title',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('administration.tasks.form.title') as string)],
      },
      ...DYNAMIC_FIELDS,
      {
        value: 'content',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'dueTo',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'assignedTo',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'resubmission',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'solvedOn',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'solvedBy',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'parent',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(
          () => tasks.value?.filter((task) => task.id !== props.taskToEdit?.id && !task.parent?.id) ?? []
        ),
        isLoading: computed(() => isLoadingTasks.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'title',
      },
    ]

    const form = ref<TaskInput | TaskUpdateInput>({} as TaskInput | TaskUpdateInput)

    const { createTask, isLoading: isLoadingCreateTask } = useCreateTask()

    async function onAddTask(): Promise<void> {
      if (!isOfType<TaskUpdateInput>(form.value, 'id')) {
        const updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, form.value)
        try {
          await createTask(updatedForm)

          addNotification({
            text: root.$t('administration.tasks.add.success') as string,
            type: 'success',
            timeout: 3000,
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateTask, isLoading: isLoadingUpdateTask } = useUpdateTask()

    async function onEditTask(): Promise<void> {
      if (isOfType<TaskOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<TaskUpdateInput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)
        try {
          await updateTask(updatedForm.id, updatedForm)

          addNotification({
            text: root.$t('administration.task.update.success') as string,
            type: 'success',
            timeout: 3000,
          })
          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditTask = computed(() => isLoadingCreateTask.value || isLoadingUpdateTask.value)

    function close(): void {
      emit('reload-tasks')

      emit('close')
    }

    watchEffect(() => props.entityId && (form.value[props.entityName] = props.entityId))

    watch(
      () => props.taskToEdit,
      () => {
        if (props.taskToEdit) {
          form.value = cloneDeep(props.taskToEdit as TaskUpdateInput)

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
        }
      },
      { immediate: true }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        form,

        isLoadingAddEditTask,
      },
      listeners: {
        onAddTask,

        onEditTask,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
